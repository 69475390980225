import * as Yup from "yup";
import validationMessages from "utils/forms/messages";

export const INITIAL_VALUES_GLOBAL = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  countryId: "",
  cityId: "",
  sectorId: "",
  companyName: "",
  companyType: "",
  message: "",
};

const { cityId, ...restOfGlobal } = INITIAL_VALUES_GLOBAL;

export const INITIAL_VALUES_USA = {
  ...restOfGlobal,
  cityLabel: "",
  zipCode: "",
  regionId: "",
  address: "",
};

export const INITIAL_VALUES_RESOURCES = {
  ...restOfGlobal,
  cityLabel: "",
  zipCode: "",
  regionId: "",
  interest: "",
};

export const VALIDATION_SCHEMA_GLOBAL = (intl) => ({
  firstName: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  lastName: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  email: Yup.string()
    .email(intl.formatMessage(validationMessages.email))
    .required(intl.formatMessage(validationMessages.required)),
  phone: Yup.number().required(intl.formatMessage(validationMessages.required)),
  countryId: Yup.string().when('isMEXICO()', {
    is: false,
    then: Yup.string().required(validationMessages.required),
    otherwise: Yup.string(),
  }),
  cityId: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  sectorId: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  companyName: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  companyType: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  message: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
});

export const VALIDATION_SCHEMA_USA = (intl) => ({
  ...restOfGlobal,
  cityLabel: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  zipCode: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  regionId: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  address: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
});

export const VALIDATION_SCHEMA_MARINE = (intl) => ({
  ...VALIDATION_SCHEMA_USA(intl),
});

export const VALIDATION_SCHEMA_RESOURCES_INJECTED_SERVICE_SLUG = (intl) => ({
  ...restOfGlobal,
  cityLabel: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  zipCode: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
  regionId: Yup.string().required(
    intl.formatMessage(validationMessages.required)
  ),
});

export const VALIDATION_SCHEMA_RESOURCES = (intl, injectedServiceSlug) => {
  if (injectedServiceSlug) {
    return { ...VALIDATION_SCHEMA_RESOURCES_INJECTED_SERVICE_SLUG(intl) };
  }
  return {
    ...VALIDATION_SCHEMA_RESOURCES_INJECTED_SERVICE_SLUG(intl),
    interest: Yup.object().shape({
      label: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
      value: Yup.string().required(
        intl.formatMessage(validationMessages.required)
      ),
    }),
  };
};

export const FORM_SCHEMA = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_GLOBAL,
    validationSchema: (intl) => VALIDATION_SCHEMA_GLOBAL(intl),
  },
  [process.env.REACT_APP_MX_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_GLOBAL,
    validationSchema: (intl) => VALIDATION_SCHEMA_GLOBAL(intl),
  },
  [process.env.REACT_APP_EU_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_GLOBAL,
    validationSchema: (intl) => VALIDATION_SCHEMA_GLOBAL(intl),
  },
  [process.env.REACT_APP_USA_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_USA,
    validationSchema: (intl) => VALIDATION_SCHEMA_USA(intl),
  },
  [process.env.REACT_APP_SM_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_USA,
    validationSchema: (intl) => VALIDATION_SCHEMA_MARINE(intl),
  },
  [process.env.REACT_APP_RC_INSTANCE_ID]: {
    initialValue: INITIAL_VALUES_RESOURCES,
    validationSchema: (intl, injectedServiceSlug) =>
      VALIDATION_SCHEMA_RESOURCES(intl, injectedServiceSlug),
  },
};
