import React from 'react'
import { useState } from 'react'
import { useQuery } from 'react-apollo'
import regionsQuery from '../query';
import { useEffect } from 'react';
import { isMARINE, isRESOURCES, isUSA } from 'services/instances';

const useLoadRegions = (countryId) => {
  const isUsaInstance = isUSA() || isMARINE() || isRESOURCES()
  const [regions, setRegions] = useState();
  const { data } = useQuery(regionsQuery, {
    variables: {
      countryId
    }
  })
  const defaultUsaRegions = isUsaInstance && regions ? { defaultOptions: regions } : null

  useEffect(() => {
    if (data) {
      const { regions: { edges } } = data

      const regionOptionsToSelect = edges.map((region) => {
        return { 
          label: region.node.name, 
          value: region.node.id 
        }
      })

      setRegions(regionOptionsToSelect);
    }
  }, [countryId, data])
  
  return { regions, defaultUsaRegions, isUsaInstance }
}

export default useLoadRegions