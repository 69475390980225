import PropTypes from "prop-types";
import "./styles/LocationInputs.scss";
import FormErrorMessage from "utils/components/FormErrorMessage";
import { CountryField } from "./components/CountryField";
import RegionField from "./components/RegionField";
import { Field, useFormikContext } from "formik";

const LocationInputs = ({ countryId }) => {
  const { values, setFieldValue } = useFormikContext();
  
  let countryIdentifier =
  typeof countryId === "object" ? countryId.value : countryId;

  const handleChangeFieldValue = (name, value) => {
    setFieldValue(name, value);
  }

  return (
    <div className="bg-white d-flex flex-column">
      <div className="mt-3">
        <CountryField className="border border-dark rounded" />
      </div>
      <div className="mt-1">
        <FormErrorMessage name="countryId" />
      </div>
      <div className="mt-3 border border-dark rounded">
        <RegionField countryId={countryIdentifier} />
      </div>
      <div className="mt-1">
        <FormErrorMessage name="regionId" />
      </div>
      <div className="mt-3">
        <Field
          id="cityName"
          name="cityName"
          className="form-control rounded border border-dark"
          value={values.cityName.label}
          onChange={(e) => handleChangeFieldValue(e.target.name, e.target.value)}
        />
      </div>
      <div className="mt-1">
        <FormErrorMessage name="cityName" />
      </div>
    </div>
  );
};

LocationInputs.propTypes = {
  field: PropTypes.object,
  countryId: PropTypes.object || PropTypes.string,
  regionId: PropTypes.object || PropTypes.string,
};

export default LocationInputs;
