
export const getUserInformationFields = (userInformation, formattedMessage) => {

  const userInformationFields = {
    profile: {
      title: formattedMessage("RecordsPage.profile"),
      fields: [
        { name: formattedMessage("RecordsPage.firstName"), value: userInformation?.me.firstName },
        { name: formattedMessage("RecordsPage.lastName"), value: userInformation?.me.lastName },
        { name: formattedMessage("RecordsPage.phone"), value: userInformation?.me.profile?.phone }
      ]
    },
    companyDetails: {
      title: formattedMessage("RecordsPage.companyDetails"),
      fields: [
        { name: formattedMessage("RecordsPage.companyName"), value: userInformation?.me.profile?.company  },
        { name: formattedMessage("RecordsPage.position"), value: userInformation?.me.profile?.position },
        { name: formattedMessage("RecordsPage.companyType"), value: userInformation?.me.profile?.companyType.name, id: userInformation?.me.profile?.companyType.id }
      ]
    },
    locationDetails: {
      title: formattedMessage("RecordsPage.locationDetails"),
      fields: [
        { name: formattedMessage("RecordsPage.country"), value: userInformation?.me.profile?.country?.name, id: userInformation?.me.profile?.country?.id },
        { name: formattedMessage("RecordsPage.state"), value: userInformation?.me.profile?.region?.name, id: userInformation?.me.profile?.region?.id },
        { name: formattedMessage("RecordsPage.city"), value: userInformation?.me.profile?.cityName, id: userInformation?.me.profile?.cityName },
      ]
    },
    addressBook: {
      title: formattedMessage("RecordsPage.addressBook"),
      fields: [
        { name: formattedMessage("ContactBoxForm.zipCodeField"), value: userInformation?.me.profile?.zipCode },
        { name: formattedMessage("RecordsPage.primaryAddress"), value: userInformation?.me.profile?.address },
      ]
    }
  };

  return userInformationFields
}