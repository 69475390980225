import PropTypes from "prop-types";
import { useQuery } from "react-apollo";
import { Field } from "formik";
import companyTypesQuery from "modules/contact/ContactUsPage/components/ContactBox/components/CompanyTypeField/query";
import { getCurrentInstanceId } from "services/instances";
import FormErrorMessage from "utils/components/FormErrorMessage";
import { CountryField } from "../LocationInputs/components/CountryField";
import RegionField from "../LocationInputs/components/RegionField";
import CityField from "../LocationInputs/components/CityField";
import "../LocationInputs/styles/LocationInputs.scss";

const FIELD_BLOCKS = {
  ["PROFILE"]: {
    isHideCompanyType: false,
    isHideLocation: false,
  },
  ["COMPANY DETAILS"]: {
    isHideCompanyType: true,
    isHideLocation: false,
  },
  ["LOCATION DETAILS"]: {
    isHideCompanyType: false,
    isHideLocation: true,
  },
  ["ADDRESS BOOK"]: {
    isHideCompanyType: false,
    isHideLocation: false,
  },
};

const RequiredInputs = ({ section, countryId, regionId }) => {
  const { data } = useQuery(companyTypesQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()],
    },
  });

  const { isHideCompanyType, isHideLocation } = FIELD_BLOCKS[section];

  const companyTypes =
    data?.companyTypes?.edges.map(({ node: { id, name } }) => {
      return {
        id: id,
        name: name,
      };
    }) || [];

  let countryIdentifier =
    typeof countryId === "object" ? countryId.value : countryId;
  let regionIdentifier =
    typeof regionId === "object" ? regionId.value : regionId;

  return (
    <>
      {!isHideCompanyType && (
        <>
          <Field
            name="companyType"
            as="select"
            className="mt-3 bg-white rounded border border-dark py-2 px-2"
          >
            {(props) => {
              const { field } = props;
              let defaultOption;

              const option = companyTypes.map((item, index) => {
                defaultOption =
                  field.value.label === item.name ? (
                    <option key="default" value={item.id}>
                      {item.name}
                    </option>
                  ) : null;
                if (field.value.label !== item.name) {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                }
              });

              const selectOptions = [defaultOption, ...option];
              return (
                <div>
                  <select
                    className="bg-white mt-3 py-2 px-2 rounded border border-dark col-12"
                    value={field.value}
                    {...field}
                  >
                    {selectOptions}
                  </select>
                </div>
              );
            }}
          </Field>
          <div className="mt-1">
            <FormErrorMessage name="companyType" />
          </div>
        </>
      )}
      {!isHideLocation && (
        <>
          <div className="mt-3">
            <CountryField className="border border-dark rounded" />
          </div>
          <div className="mt-1">
            <FormErrorMessage name="countryId" />
          </div>
          <div className="mt-3 border border-dark rounded">
            <RegionField countryId={countryIdentifier} />
          </div>
          <div className="mt-1">
            <FormErrorMessage name="regionId" />
          </div>
          <div className="mt-3">
            <Field
              id="cityLabel"
              name="cityLabel"
              className="form-control form-control-lg"
            />
          </div>
          <div className="mt-1">
            <FormErrorMessage name="cityLabel" />
          </div>
        </>
      )}
    </>
  );
};

RequiredInputs.propTypes = {
  section: PropTypes.string,
  field: PropTypes.object,
  countryId: PropTypes.object || PropTypes.string,
  regionId: PropTypes.object || PropTypes.string,
};

export default RequiredInputs;
