import { useState } from "react";
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "modules/contact/ContactUsPage/components/ContactBox/styles/ContactBox.scss";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getCurrentInstanceId,
  isMARINE,
  isMEXICO,
  isRESOURCES,
  isUSA,
} from "services/instances";
import { useMutation } from "react-apollo";
import {
  createContactMutation,
  createResourceContactMutation,
} from "modules/contact/ContactUsPage/components/ContactBox/mutation";
import { FORM_SCHEMA } from "modules/contact/ContactUsPage/components/ContactBox/constants";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CountriesField from "components/AuthModal/components/SignUpForm/components/CountriesField";
import CityField from "components/AuthModal/components/SignUpForm/components/CityField";
import SectorsField from "modules/contact/ContactUsPage/components/ContactBox/components/SectorsField";
import PrivacyPolicyCheckbox from "utils/components/PrivacyPolicyCheckbox";
import TitleUnderlined from "utils/components/TitleUnderlined";
import FormErrorMessage from "utils/components/FormErrorMessage";
import { H1_TAG } from "utils/components/TitleUnderlined/constants";
import useToggle from "utils/hooks/useToggable";
import useLocationByZipcode from "utils/hooks/useLocationByZipcode";
import CompanyTypeField from "./components/CompanyTypeField";
import RegionField from "components/AuthModal/components/SignUpForm/components/RegionField";
import ServicesAndPatternsField from "./components/ServicesAndPatternsField";
import { handleCountryIdForMX } from "./constants/handleCountryIdForMX";

export default function ContactBox({
  title,
  message: label,
  injectedValues = {},
}) {
  const intl = useIntl();
  const [success, setSuccess] = useToggle();
  const newContactMutation = !isRESOURCES()
    ? createContactMutation
    : createResourceContactMutation;
  const [createContact] = useMutation(newContactMutation, {
    onCompleted() {
      setSuccess(true);
    },
  });
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const { locationByZipcode, handleLocationByZipcode } = useLocationByZipcode();
  const { serviceSlug: injectedServiceSlug } = injectedValues;
  const initialValues = FORM_SCHEMA[getCurrentInstanceId()].initialValue;
  const validationSchema = Yup.object(
    FORM_SCHEMA[getCurrentInstanceId()].validationSchema(
      intl,
      injectedServiceSlug
    )
  );

  /**
   * Handles change on the privacy policy checkbox
   * @param checked {Boolean}
   */
  function handlePrivacyPolicyChange(checked) {
    setPrivacyPolicyAccepted(checked);
  }

  /**
   * Handler of the form submit.
   * Calls createContactMutation with the form values
   * @param values {Object}
   * @param setSubmitting {Function}
   */
  function onSubmit(values, { setSubmitting }) {
    const { countryId, regionId, interest, ...payload } = values; // No send country field to backend

    const input = !isRESOURCES()
      ? {
        instanceId: getCurrentInstanceId(),
        source: "contact form",
        ...payload,
      }
      : {
        instanceId: getCurrentInstanceId(),
        ...(injectedServiceSlug && { serviceSlug: injectedServiceSlug }),
        ...(interest &&
          interest.__typename === "ServiceNode" && {
          serviceSlug: interest.value,
        }),
        ...(interest &&
          interest.__typename === "PatternNode" && {
          patternSlug: interest.value,
        }),
        source: !injectedServiceSlug ? "form-contact-us" : "form-service",
        ...payload,
      };

    setSubmitting(true);
    createContact({ variables: { input } })
      .then(() => {
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  return (
    <div
      className={classNames({
        "contact-box": true,
        "contact-box--marine": isMARINE(),
        "contact-box--resources": isRESOURCES(),
      })}
    >
      <div className="row justify-content-center">
        {success ? (
          <div className="pt-5 pb-5 white text-center">
            <TitleUnderlined color="white" align="center">
              <FormattedMessage
                id="ContactBox.successMessageTitle"
                defaultMessage="¡Gracias por contactarnos!"
              />
            </TitleUnderlined>
            <p>
              <FormattedMessage
                id="ContactBox.successMessageSubtitle"
                defaultMessage="Pronto nos pondremos en contacto contigo."
              />
            </p>
          </div>
        ) : (
          <>
            {title && label && (
              <div className="col-12 col-lg-5 align-self-center px-md-5 px-lg-3">
                {title && (
                  <TitleUnderlined
                    hTag={H1_TAG}
                    className="text-size-h1 text-weight-bold mb-4 pt-4 pt-lg-5 mb-lg-5"
                    color="white"
                  >
                    {title}
                  </TitleUnderlined>
                )}
                <p className="contact-box-text">{label}</p>
              </div>
            )}
            <div
              className={classNames({
                "col-12": true,
                "col-lg-6": title && label,
              })}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values: { countryId, regionId, interest, zipCode },
                  errors,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form
                    id="contact-us-form"
                    noValidate
                    className="form contact-box-form"
                  >
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="firstName">
                            <FormattedMessage
                              id="ContactBoxForm.firstNameField"
                              description="First name field of the Contact form"
                              defaultMessage="Nombre"
                            />
                            *
                          </label>
                          <Field
                            id="firstName"
                            name="firstName"
                            type="text"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="firstName" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="lastName">
                            <FormattedMessage
                              id="ContactBoxForm.lastNameField"
                              description="Last name field of the Contact form"
                              defaultMessage="Apellido"
                            />
                            *
                          </label>
                          <Field
                            id="lastName"
                            name="lastName"
                            type="text"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="lastName" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            <FormattedMessage
                              id="ContactBoxForm.emailField"
                              description="Email field of the Contact form"
                              defaultMessage="Email"
                            />
                            *
                          </label>
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="email" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="phone">
                            <FormattedMessage
                              id="ContactBoxForm.phoneField"
                              description="Phone field of the Contact form"
                              defaultMessage="Teléfono"
                            />
                            *
                          </label>
                          <Field
                            id="phone"
                            name="phone"
                            type="number"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="phone" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className={classNames(
                          isUSA() || isMARINE() || isRESOURCES()
                            ? "col-md-6"
                            : "col-md-12"
                        )}
                      >
                        <div className="form-group">
                          <label htmlFor="companyName">
                            <FormattedMessage
                              id="ContactBoxForm.companyField"
                              description="Company field of the Contact form"
                              defaultMessage="Empresa"
                            />
                            *
                          </label>
                          <Field
                            id="companyName"
                            name="companyName"
                            type="text"
                            className="form-control form-control-lg"
                          />
                          <FormErrorMessage color="white" name="companyName" />
                        </div>
                      </div>
                      {(isUSA() || isMARINE() || isRESOURCES()) && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="zipCode">
                              <FormattedMessage
                                id="ContactBoxForm.zipCodeField"
                                description="Zip code field of the Contact form"
                                defaultMessage="Zip code"
                              />
                              *
                            </label>
                            <Field
                              id="zipCode"
                              name="zipCode"
                              className="form-control form-control-lg"
                              onBlur={() => handleLocationByZipcode(zipCode)}
                            />
                            <FormErrorMessage color="white" name="zipCode" />
                          </div>
                        </div>
                      )}
                    </div>
                    {isUSA() || isMARINE() || isRESOURCES() ? (
                      <>
                        <div className="form-row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="countryId">
                                <FormattedMessage
                                  id="ContactBoxForm.countryField"
                                  description="Country field of the Contact form"
                                  defaultMessage="País"
                                />
                                *
                              </label>
                              <CountriesField
                                className="form-control-lg"
                                countryByZipcode={locationByZipcode?.country}
                              />
                              <FormErrorMessage
                                color="white"
                                name="countryId"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="regionId">
                                <FormattedMessage
                                  id="SignUpForm.regionId"
                                  description="Region field of the Sign Up form"
                                  defaultMessage="Región"
                                />
                                *
                              </label>
                              <RegionField
                                countryId={countryId}
                                isCustomStyles
                                regionByZipcode={locationByZipcode?.state}
                              />
                              <FormErrorMessage color="white" name="regionId" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="cityLabel">
                                <FormattedMessage
                                  id="ContactBoxForm.cityField"
                                  description="City field of the Contact form"
                                  defaultMessage="Ciudad"
                                />
                                *
                              </label>
                              <Field 
                                id="cityLabel"
                                name="cityLabel"
                                className="form-control form-control-lg"
                              />
                              <FormErrorMessage color="white" name="cityLabel" />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="form-row">
                        {!isMEXICO() &&
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="countryId">
                                <FormattedMessage
                                  id="ContactBoxForm.countryField"
                                  description="Country field of the Contact form"
                                  defaultMessage="País"
                                />
                                *
                              </label>
                              <CountriesField className="form-control-lg" />
                              <FormErrorMessage color="white" name="countryId" />
                            </div>
                          </div>
                        }
                        {isMEXICO() &&
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="regionId">
                                {!isMEXICO()
                                  ? <FormattedMessage
                                    id="SignUpForm.regionId"
                                    description="Region field of the Contact form"
                                    defaultMessage="Región"
                                  />
                                  : <FormattedMessage
                                    id="SignUpForm.stateId"
                                    description="State field of the Contact form"
                                    defaultMessage="Estado"
                                  />}
                                *
                              </label>
                              <RegionField
                                className="form-control"
                                countryId={handleCountryIdForMX(countryId)}
                                isCustomStyles
                              />
                              <FormErrorMessage color="black" name="regionId" />
                            </div>
                          </div>
                        }
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="cityId">
                              {!isMEXICO()
                                ? <FormattedMessage
                                  id="ContactBoxForm.cityField"
                                  description="City field of the Contact form"
                                  defaultMessage="Ciudad"
                                />
                                : <FormattedMessage
                                  id="ContactBoxForm.cityFieldMx"
                                  description="City field of the Contact form"
                                  defaultMessage="Delegación"
                                />}
                              *
                            </label>
                            {isMEXICO()
                              ?
                              <CityField
                                className="form-control-lg"
                                regionId={regionId}
                                isCustomStyles
                              />
                              :
                              <CityField
                                className="form-control-lg"
                                regionId={countryId}
                                searchByCountryId
                                isCustomStyles
                              />
                            }
                            <FormErrorMessage color="white" name="cityId" />
                          </div>
                        </div>
                      </div>
                    )}
                    {!isRESOURCES() && (
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="address">
                              <FormattedMessage
                                id="SignUpForm.address"
                                defaultMessage="Address"
                              />
                              *
                            </label>
                            <Field
                              id="address"
                              name="address"
                              className="form-control form-control-lg"
                            />
                            <FormErrorMessage color="white" name="address" />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="sectorId">
                            <FormattedMessage
                              id="ContactBoxForm.sectorField"
                              description="Sector field of the Contact form"
                              defaultMessage="Sector"
                            />
                          </label>
                          <SectorsField />
                          <FormErrorMessage color="white" name="sectorId" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="companyType">
                            <FormattedMessage
                              id="ContactBoxForm.companyTypeField"
                              description="Company type field of the Contact form"
                              defaultMessage="Tipo de empresa"
                            />
                          </label>
                          <CompanyTypeField className="form-control-lg" />
                          <FormErrorMessage color="white" name="companyType" />
                        </div>
                      </div>
                    </div>
                    {isRESOURCES() && !injectedServiceSlug && (
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="interest">
                              <FormattedMessage
                                id="ContactBoxForm.serviceField"
                                description="Service field of the Contact form"
                                defaultMessage="Service/Product of Interest"
                              />
                            </label>
                            <ServicesAndPatternsField
                              name="interest"
                              value={interest}
                              errors={errors}
                              handleOnChange={setFieldValue}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <label htmlFor="message">
                        {!isRESOURCES() ? (
                          <FormattedMessage
                            id="ContactBoxForm.messageField"
                            description="Message field of the Contact form"
                            defaultMessage="Message"
                          />
                        ) : (
                          <FormattedMessage
                            id="ContactBoxForm.messageResourcesField"
                            description="Message field for ps resources of the Contact form"
                            defaultMessage="How can we help you?"
                          />
                        )}
                        *
                      </label>
                      <Field
                        as="textarea"
                        id="message"
                        name="message"
                        className="form-control form-control-lg"
                        rows={5}
                      />
                      <FormErrorMessage color="white" name="message" />
                    </div>
                    <PrivacyPolicyCheckbox
                      onChange={handlePrivacyPolicyChange}
                    />
                    <div className="text-center text-md-left">
                      <button
                        disabled={isSubmitting || !privacyPolicyAccepted}
                        type="submit"
                        className="btn btn-dark"
                      >
                        {isSubmitting ? (
                          <FormattedMessage
                            id="ContactBox.submitButtonLoading"
                            description="Submit button label of the Contact form in loading state"
                            defaultMessage="Enviando"
                          />
                        ) : (
                          <>
                            {!isRESOURCES() ? (
                              <FormattedMessage
                                id="ContactBox.submitButtonLabel"
                                description="Submit button label for contact box form"
                                defaultMessage="Enviar"
                              />
                            ) : (
                              <FormattedMessage
                                id="ContactBox.submitResourcesButtonLabel"
                                description="Submit button label for sp resources contact box form"
                                defaultMessage="Contact Us"
                              />
                            )}
                          </>
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ContactBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  injectedValues: PropTypes.object,
};
